// smoothProgressBar.js

export default {
  smoothDragProgressBar(player) {
    const videojs = player.constructor;  // Access the videojs constructor via the player instance
    const SeekBar = videojs.getComponent("SeekBar");
    const originalGetPercent = SeekBar.prototype.getPercent;

    SeekBar.prototype.getPercent = function getPercent() {
      const time = this.player_.currentTime();
      const percent = time / this.player_.duration();
      return percent >= 1 ? 1 : percent;
    };

    SeekBar.prototype.DownMove = function DownMove(event) {
      let newTime = this.calculateDistance(event) * this.player_.duration();
      if (newTime === this.player_.duration()) {
        newTime = newTime - 0.1;
      }
      this.player_.currentTime(newTime);
      this.update();

      const currentTime = player.currentTime();
      const minutes = Math.floor(currentTime / 60);
      const seconds = Math.floor(currentTime - minutes * 60);
      const x = minutes < 10 ? "0" + minutes : minutes;
      const y = seconds < 10 ? "0" + seconds : seconds;
      const format = x + ":" + y;

      player.controlBar.currentTimeDisplay.el_.innerHTML = format;
    };

    // Return a function that reverts the prototype back to its original state
    return () => {
      SeekBar.prototype.getPercent = originalGetPercent;
      delete SeekBar.prototype.DownMove;
    };
  }
};
