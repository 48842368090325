var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"player-atc-wrapper",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.isPopup && _vm.changeIframe('destroyIframe')}}},[_c('div',{directives:[{name:"visibility-change",rawName:"v-visibility-change",value:(_vm.visibilityChange),expression:"visibilityChange"}],ref:"playerContainer",staticClass:"player-container",class:[
      {
        'is-story': _vm.isStory,
        isSquare: _vm.isSquare,
        isPortrait: _vm.isPortrait && !_vm.isSquare,
        isPopup: _vm.isPopup,
      },
      { isLoading: _vm.isLoading && _vm.isCurrentVideo },
      { isTrimmedPlaying: _vm.isTrimmedPlaying },
      { hideControlBar: _vm.hideControlBar },
    ],style:(_vm.borderRadiusStyle),on:{"mouseover":function($event){return _vm.hideTitleAndShowControlsOnHover()},"mouseleave":_vm.showTitleAndHideControlsOnLeave}},[(_vm.showCloseButton)?_c('close-button',{attrs:{"video-index":_vm.videoIndex}}):_vm._e(),(_vm.showPlayerText)?_c('player-text',{attrs:{"video-index":_vm.videoIndex}}):_vm._e(),_c('video',{ref:"videoPlayer",class:[
        'vjs-vidjet-main',
        'video-js',
        { isFullScreen: _vm.isFullScreen },
        { hideVideo: _vm.hideVideo },
      ],style:([..._vm.videoStyle, _vm.borderRadiusStyle]),attrs:{"preload":"metadata","poster":_vm.thumbnail,"playsinline":"","muted":""},domProps:{"muted":true},on:{"mousedown":_vm.handleClickDown,"mouseup":_vm.handleClickUp,"touchstart":_vm.handleClickDown,"touchend":_vm.handleClickUp}}),_c('PlayPause',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayPause && !_vm.isLoading),expression:"showPlayPause && !isLoading"}],staticClass:"play-pause-container",attrs:{"is-small":_vm.isStory && !_vm.isPlayerOpened,"showPlayButton":_vm.showPlayButton,"showPauseButton":_vm.isUserActive,"hasNotClicked":_vm.hasNotClicked},nativeOn:{"click":function($event){return _vm.togglePlayPause()},"touchend":function($event){return _vm.togglePlayPause()}}}),(_vm.showNavigationArrows)?_c('NavigationArrows',{staticClass:"navigation-arrows-container",attrs:{"video-index":_vm.videoIndex}}):_vm._e(),_c('div',{staticClass:"player-interface"},[(_vm.showPlayerInterface)?_c('PlayerInterface',{attrs:{"showTitle":!_vm.showControls && !_vm.isMultiple && !_vm.isFullScreen,"showControls":_vm.showControls || _vm.player.paused(),"video-index":_vm.videoIndex,"is-current-video":_vm.isCurrentVideo,"is-cta-within-range":_vm.isCtaWithinRange},on:{"notify-preview":function($event){return _vm.$emit('notify-preview')},"pause-video":function($event){return _vm.player.pause()}}}):_vm._e()],1),_c('SpinnerLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading && _vm.isCurrentVideo && _vm.isPlayerOpened),expression:"isLoading && isCurrentVideo && isPlayerOpened"}],attrs:{"isBigSpinner":false,"background":"transparent"}})],1),(_vm.showAtcFullScreen && _vm.isCtaWithinRange)?_c('div',{staticClass:"atc-form"},[_c('atc-multiple-products',{staticStyle:{"height":"calc(var(--vh, 1vh) * 100)"},attrs:{"video-index":_vm.videoIndex}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }