const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  const minutesString = (minutes < 10 ? '0' : '') + minutes;
  const secondsString = (seconds < 10 ? '0' : '') + seconds;

  return minutesString + ':' + secondsString;
}

export default {
  attachTooltip(player, isMobile) {
    const progressControl = player.controlBar ? player.controlBar.progressControl : null;
    const tooltip = document.createElement('div');
    tooltip.className = 'vidjet-time-tooltip';
    tooltip.style.opacity = '0';  // Initially hidden
    tooltip.style.transition = 'opacity 0.3s ease'; // For smooth fading

    if (progressControl && progressControl.el()) {
      progressControl.el().appendChild(tooltip);
    }

    let tooltipTimeout;  // Declare timeout variable outside of event listener

    const updateTooltip = (event) => {
      // Clear the previous timeout to ensure the tooltip stays visible while moving
      clearTimeout(tooltipTimeout);

      const progress = progressControl.seekBar;
      const seekBarEl = progress.el();
      const seekBarRect = seekBarEl.getBoundingClientRect();
      const duration = player.duration();

      let clientX;
      if (event.touches) {
        clientX = event.touches[0].clientX;
      } else {
        clientX = event.clientX;
      }

      const seekBarStart = seekBarRect.left;
      const seekBarWidth = seekBarRect.width;

      const mouseTime = (clientX - seekBarStart) / seekBarWidth * duration;

      tooltip.innerHTML = formatTime(mouseTime); // use player.formatTime instead of videojs
      tooltip.style.left = (clientX - seekBarStart) + 'px';
      tooltip.style.bottom = "20px";
      tooltip.style.opacity = '1';  // Make tooltip visible

      // Set a timeout to hide the tooltip after 2 seconds
      tooltipTimeout = setTimeout(() => {
        tooltip.style.opacity = '0';
      }, 1000);
    };

    if (progressControl && progressControl.el()) {
      if (isMobile) {
        progressControl.el().addEventListener('touchmove', updateTooltip);
      } else {
        progressControl.el().addEventListener('mousemove', updateTooltip);
      }
    }

    // Return a function that can be called to unbind the listeners
    return () => {
      if (!progressControl || !progressControl.el()) {
        // Progress control or its DOM element does not exist, exit function
        return () => { };
      }
      if (isMobile) {
        progressControl.el().removeEventListener('touchmove', updateTooltip);
      } else {
        progressControl.el().removeEventListener('mousemove', updateTooltip);
      }

      // Clear any remaining tooltip timeout when unbinding
      clearTimeout(tooltipTimeout);
    };
  }
};
